import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { models } from 'powerbi-client'

import { Context } from 'contexts/context'

import BtnOrange from 'components/Button/BtnOrange'

import { handleReport } from 'services/api/reports'

import { handlePowerBiReportAad, handlePowerBiReportEmbed } from 'services/api/powerbi'

import { PowerBIEmbed } from 'powerbi-client-react'

import './style.css'

const Page = () => {
  const [data, setData] = useState({})
  const [report, setReport] = useState()
  const [isLoaded, setIsLoaded] = useState(false)

  const params = useParams()

  const { user } = useContext(Context)
  
  const { group_id, report_id, dataset_id } = params

  const getData = async () => {
    if (user.roles === 'admin') {
      const reportData = await handlePowerBiReportAad({ report_id })

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.accessToken,
        type: models.TokenType.Aad
      })

      return reportData.accessToken;
    }

    const roles = await handleReport(report_id, user.id)

    if (roles.roles) {
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        roles: roles.roles
      })

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        page_navigation: roles.page_navigation,
        enable_filters: roles.enable_filters,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration
      })

      return reportData.embedToken;
    }

    const reportData = await handlePowerBiReportAad({ report_id })

    setData({
      reportId: reportData.reportId,
      embedUrl: reportData.embedUrl,
      token: reportData.accessToken,
      page_navigation: roles.page_navigation,
      enable_filters: roles.enable_filters,
      type: models.TokenType.Aad
    })

    return reportData.embedToken;
  }

  useEffect(() => {
    if (user) getData()
  }, [user])

  window.screen.orientation.onchange = event => {
    if (event.target.type === "landscape-primary") {
      report.fullscreen()

      return
    }

    if (document.fullscreenElement) report.exitFullscreen()
  }

  const embedConfig = {
    type: 'report',
    id: data.reportId,
    embedUrl: data.embedUrl,
    eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
    accessToken: data.token,
    tokenType: data.type || models.TokenType.Aad,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
         displayOption: models.DisplayOption.FitToPage
      },
      panes: {
        filters: {
          visible: data.enable_filters ? data.enable_filters : false
        },
        pageNavigation: {
          visible: data.page_navigation ? data.page_navigation : false
        }
      }
    }
  }

  return (
    <div id='ReportPageContainer'>
      {isLoaded && (user.view_report_options === 1 || user.roles === "admin") && (
        <div id='ReportContainer'>
          <div id='ReportContainerOptions'>
            <BtnOrange
              onClick={() => report.fullscreen()}
              isLoading={false}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                padding: 0,
                backgroundColor: 'var(--color-green)'
              }}
            >
              <img
                src="/svgs/fullscreen.svg"
                className='imageButtonReport'
                alt={"icon"}
              />
            </BtnOrange>

            <BtnOrange
              onClick={() => report.print()}
              isLoading={false}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                padding: 0,
                backgroundColor: 'var(--color-green)'
              }}
            >
              <img
                src="/svgs/print.svg"
                className='imageButtonReport'
                alt={"icon"}
              />
            </BtnOrange>

            <BtnOrange
              onClick={() => report.refresh()}
              isLoading={false}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                padding: 0,
                backgroundColor: 'var(--color-green)'
              }}
            >
              <img
                src="/svgs/autorenew.svg"
                className='imageButtonReport'
                alt={"icon"}
              />
            </BtnOrange>
          </div>
        </div>
      )}

      <PowerBIEmbed
        eventHandlers = {
          new Map([
              ['loaded', () => console.log("Report loaded")],
              ['rendered', () => setIsLoaded(true)],
              ['error', event => console.log(event.detail)]
          ])
        }
        getEmbeddedComponent={(embedObject) => setReport(embedObject)}
        embedConfig={embedConfig}
        cssClassName={"report"}
      />
    </div>
  )
}

export default Page