import { BrowserRouter, Switch, Route } from 'react-router-dom'
import GlobalStyle from 'main/GlobalStyle'
import Public from 'components/Routes/Public'
import Private from 'components/Routes/Private'
import Template from 'components/Template'
import TemplateLogin from 'components/TemplateLogin'

import ContextProvider from 'contexts/context'

import NoMatch from 'pages/NoMatch'
import Login from 'pages/Login'
import ForgotPassword from 'pages/ForgotPassword'
import RecoverPassword from 'pages/RecoverPassword'
import Profile from 'pages/Profile'

import Index from 'pages/Index'

import Groups from 'pages/Groups'
import Reports from 'pages/Reports'
import Report from 'pages/Report'

import Cards from 'pages/Cards'
import CardsStore from 'pages/CardsStore'
import CardsUpdate from 'pages/CardsUpdate'
import CardsUpdateImage from 'pages/CardsUpdateImage'

import Users from 'pages/Users'
import UsersStore from 'pages/UsersStore'
import UsersUpdate from 'pages/UsersUpdate'
import ReportsUser from 'pages/UserReports'
import ReportsUserStore from 'pages/ReportsUserStore'
import ReportsUserUpdate from 'pages/ReportsUserUpdate'

import Error from 'pages/Error'

const App = () => {
	return (
		<>
			<GlobalStyle />
			<ContextProvider>
				<BrowserRouter>
					<Switch>
						<Public path="/login" component={TemplateLogin(Login)} redirect="/dashboard" />
						<Public path="/esqueci-senha" component={TemplateLogin(ForgotPassword)} redirect="/dashboard" />
						<Public path="/recuperar-senha/:token" component={TemplateLogin(RecoverPassword)} redirect="/dashboard" />
						<Private path="/profile" component={Template(Profile)} redirect="/login" />

						<Private
							path="/grupos/:group_id/relatorios/:report_id/datasets/:dataset_id"
							component={Template(Report)}
							redirect="/login"
						/>
						<Private path="/grupos/:group_id/relatorios" component={Template(Reports)} redirect="/login" />
						<Private path="/grupos" component={Template(Groups)} redirect="/login" />

						<Private path="/cards/:id/upload" component={Template(CardsUpdateImage)} redirect="/login" />
						<Private path="/cards/:id/editar" component={Template(CardsUpdate)} redirect="/login" />
						<Private path="/cards/cadastrar" component={Template(CardsStore)} redirect="/login" />
						<Private path="/cards" component={Template(Cards)} redirect="/login" />

						<Private path="/relatorios" component={Template(Reports)} redirect="/login" />

						<Private path="/usuarios/:id/relatorios/:report_id/editar" component={Template(ReportsUserUpdate)} redirect="/login" />
						<Private path="/usuarios/:id/relatorios/cadastrar" component={Template(ReportsUserStore)} redirect="/login" />
						<Private path="/usuarios/:id/relatorios" component={Template(ReportsUser)} redirect="/login" />
						<Private path="/usuarios/:id/editar" component={Template(UsersUpdate)} redirect="/login" />
						<Private path="/usuarios/cadastrar" component={Template(UsersStore)} redirect="/login" />
						<Private path="/usuarios" component={Template(Users)} redirect="/login" />

						<Private path="/error" component={Template(Error)} redirect="/login" />

						<Private path="/" component={Index} redirect="/login" />
						<Route path="*"><NoMatch /></Route>
					</Switch>
				</BrowserRouter>
			</ContextProvider>
		</>
	)
}

export default App