import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FaEdit } from "react-icons/fa"
import { useFormik } from 'formik'
import Swal from "sweetalert2"

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Checkbox from 'components/Form/LabelCheckbox'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'

import { handleReportsByUserWithCardWithoutGroups } from 'services/api/reports'
import { handleUserShow, handleUpdateViewReportOptions } from 'services/api/users'

const Page = () => {
    const [data, setData] = useState([])
    const [user, setUser] = useState({})

    const params = useParams()

    const formik = useFormik({
      initialValues: {
        view_report_options: !!user.view_report_options
      },
      enableReinitialize: true
    })

    useEffect(() => {
        (async () => {
            setUser(await handleUserShow(params.id))
            setData(await handleReportsByUserWithCardWithoutGroups(params.id))
        })()
    }, [params.id])

    const handleSetViewReportOptions = async () => {
      const viewReportOptions = !formik.values.view_report_options ? 1 : 0

      try {
        await handleUpdateViewReportOptions({
          view_report_options: viewReportOptions
        }, user.id)

        Swal.fire(
          'Sucesso',
          'Atualização efetuada com sucesso!',
          'success'
      )
      } catch(error) {
        console.log(error);
        Swal.fire(
          'Erro',
          'Houve um problema ao atualizar esta opção!',
          'error'
      )
      }
    }

    return (
        <>
            <TitleBar label="Usuários" currentPage="" />

            <Content>
                <Card>
                    <CardTitle title="Usuário" />

                    <Table>
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Permissão</th>
                            <th>Visualizar opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.roles}</td>

                            <td style={{ paddingLeft: window.innerWidth <= 600 ? "2rem" : "3rem" }}>
                              {user.id && <Checkbox onClick={handleSetViewReportOptions} name='view_report_options' label='' formik={formik} checked={user.view_report_options} />}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Card>

                <Card>
                    <CardTitle title="Relatórios">
                        <LinkBlue to={`/usuarios/${params.id}/relatorios/cadastrar`}>Cadastrar</LinkBlue>
                    </CardTitle>

                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th>Relatório</th>
                                <th>Roles</th>
                                <th>Abas habilitadas</th>
                                <th>Filtros habilitados</th>
                                <th width="50">Ações</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map(item =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.roles}</td>
                                    <td>{item.enable_filters ? 'Sim' : 'Não'}</td>
                                    <td>{item.page_navigation ? 'Sim' : 'Não'}</td>

                                    <td>
                                        <SmLinkBlue to={`/usuarios/${params.id}/relatorios/${item.id}/editar`}>
                                            <FaEdit />
                                        </SmLinkBlue>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>
            </Content>
        </>
    )
}

export default Page
