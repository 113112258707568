import { createGlobalStyle } from 'styled-components'

import '@coreui/coreui/dist/css/coreui.min.css'
//00AF30
const GlobalStyle = createGlobalStyle`
    :root {
        --color-sidebar: #214365;
        --color-sidebar-hover: #5D5FEF;
        --color-background: #f8f8f8;
        --color-background-card: #ffffff;
        --color-background-title-bar: #f8f8f8;
        --color-background-footer: #f8f8f8;
        --color-background-input: #f2fafd;
        --color-background-report-card: #f2fafd;
        --color-font: #214365;
        --color-icons: #5D5FEF;
        --color-white: #FFFFFF;
        --color-grey: #d3cede;
        --color-blue: #0180c3;
        --color-green: #5D5FEF;
        --color-orange: #5D5FEF;
        --color-red: #F55859;
        --color-yellow: #5D5FEF;
        --color-fff: #fff;
        --color-000: #fff;
    }
    * {
        box-sizing: border-box;
    }
    body {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0;
		padding: 0;
        background-color: var(--color-background);
        color: var(--color-font);
        max-width: 100vw;
        overflow-x: hidden;
    }
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    .swal2-styled.swal2-confirm {
        background-color: var(--color-yellow);
    }
`
export default GlobalStyle